<template>
  <!-- 出库详情 -->
  <div class="warehouseDetails page-info-content">
    <DetailsPage :detail-obj="inventoryDetailObj" :details-item-arr="detailsItemArr" table-title="货位明细" :item-data="itemData" :list-data="inventoryDetailObj.wmsInventoryOutdetailRespList" />
    <ProductNamePopUpLayer :id="pnid" ref="ProductNamePopUpLayer" />
  </div>
</template>

<script>
import { timestampToTime } from '../../../unit/index'
import ProductNamePopUpLayer from '../components/ProductNamePopUpLayer'
import DetailsPage from '@/components/DetailsPage'
import { inventoryOutDetail } from '../../../api/warehousingSystem'
export default {
  components: { ProductNamePopUpLayer, DetailsPage },
  data() {
    return {
      pnid: '',
      inventoryDetailObj: {},
      detailsItemArr: [
        { label: '出库单号', value: 'outNo' },
        { label: '出库时间', value: 'outDate' },
        { label: '仓库名称', value: 'warehouseName' },
        // { label: '提货人', value: 'consignee' },
        { label: '货主名称', value: 'custName' },
        { label: '运单号', value: 'waybillNo' },
        { label: '车牌号', value: 'carNo' },
        { label: '司机姓名', value: 'driverName' },
        { label: '司机电话', value: 'driverPhone' },
        { label: '出库验证码', value: 'checkCode' }
      ],
      itemData: [
        // { label: '出库数量', prop: 'detailOutAmount' },
        { label: '出库数量/单位', prop: 'detailOutWeight', dynamicUnit: 'unit' },
        // { label: '库存数量', prop: 'detailInvAmount' },
        { label: '库存数量/单位', prop: 'detailInvWeight', dynamicUnit: 'unit' },
        { label: '品名', prop: 'detailGoodsName' },
        { label: '材质', prop: 'detailMaterials' },
        { label: '规格', prop: 'detailSpecs' },
        { label: '产地', prop: 'detailProductions' },
        { label: '钢卷号', prop: 'detailCoilNo' },
        { label: '仓库编号', prop: 'detailWarehouseNo' },
        { label: '货位号', prop: 'detailLocationNo' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/warehousingSystem/warehouseManagement', name: '出库管理' }, { path: '', name: '库存详情' }]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      inventoryOutDetail({ id: this.$route.query.id }, res => {
        this.inventoryDetailObj = { ...res.data }
        this.pnid = this.inventoryDetailObj.goodsWeightsId
        const arr = this.$store.getters.getDictionaryItem('purchaseUnit') || []
        this.inventoryDetailObj.wmsInventoryOutdetailRespList.forEach(item => {
          item.unit = (arr.find(val => val.dictId === item.purchaseUnit) || { dictName: '吨' }).dictName
        })
        this.inventoryDetailObj.outDate = timestampToTime(this.inventoryDetailObj.outDate)
      })
    }
  },
  methods: {
    open() {
      this.$refs.ProductNamePopUpLayer.dialogTableVisible = true
    }
  }
}
</script>

<style>
.warehouseDetails .el-descriptions {
  padding: 40px 32px 0;
}

.cargoDetail {
  padding: 10px 32px;
}

.warehouseDetails .el-descriptions-row td {
  padding-bottom: 28px !important;
}

.warehouseDetails .tableBox {
  margin: auto;
  text-align: center;
  width: calc(100% - 128px);
  border-collapse: collapse;
}

.warehouseDetails .tableBox td {
  height: 40px;
  border: 1px solid #ededed;
}

.warehouseDetails .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}

.warehouseDetails .el-descriptions .el-descriptions__body {
  padding: 0 32px;
}
</style>
