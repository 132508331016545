<template>
  <!-- 详情页面 -->
  <div class="detailsPageBox">
    <el-descriptions class="" :title="detailsTitle" :column="column" size="medium">
      <el-descriptions-item v-for="(item, index) in detailsItemArr" :key="index" :label="item.label">
        <!-- 下载PDF的链接 -->
        <span v-if="item.download" class="link" @click="downloadFile(detailObj[item.download] || item.download,detailObj[item.value])">{{
          detailObj[item.value]
        }}</span>
        <!-- 窗口打开的链接 -->
        <span v-else-if="item.link" class="link" @click="viewFile(detailObj[item.link] || item.link)">{{
          detailObj[item.value]
        }}</span>
        <!-- 图片的 -->
        <div v-else-if="item.imageSrc">
          <img v-if="detailObj[item.imageSrc]" class="imageBox" :src="$fileUrl + detailObj[item.imageSrc]" @click.stop="previewPicture(detailObj[item.imageSrc])">
          <div v-else class="default-graph">
            <img :src="$errImage" alt="">
          </div>
        </div>

        <!-- 有条件判断的 -->
        <div v-for="(nape, i) in item.child" v-else-if="item.child" :key="i">
          <span v-if="(nape.id || nape.dictId) == detailObj[item.value]">{{ nape.val || nape.dictName }}</span>
        </div>
        <!-- 金额 按千位分隔 -->
        <span v-else-if="item.money"><i v-if="item.leftWords">{{ item.leftWords }}</i>{{ detailObj[item.money] | numberToCurrency }}<i
          v-if="item.rightWords"
        >{{ item.rightWords }}</i>
        </span>
        <!-- 按钮点击跳转 -->
        <span v-else-if="item.path">
          <router-link class="path" :to="{ path: item.path, query: {...item.queryObj} }">
            {{ item.leftWords||detailObj[item.value] }}
          </router-link>
        </span>
        <!-- 普通型的 -->
        <span v-else><i v-if="item.leftWords">{{ item.leftWords }}</i>{{ detailObj[item.value] }}<i
          v-if="item.rightWords"
        >{{ item.rightWords }}</i></span>
      </el-descriptions-item>
    </el-descriptions>
    <slot />
    <!-- 如果有表格的话就填上去了   没有就为空 -->
    <el-descriptions v-if="tableTitle" class="cargoDetail" :title="tableTitle" :column="column" size="medium" />
    <Table v-if="itemData.length > 0" class="tableBox" :list-data="listData" :item-data="itemData" />

    <el-dialog :visible.sync="dialogImageVisible" :close-on-click-modal="false">
      <img :src="previewImg" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { downloadFile } from '@/unit/index'
import Table from './Table.vue'
// import { BSERURL } from '@/config/index'
/*
column是一行有几个  默认是3个
detailsItemArr有哪些项目  其中的label是那一项的名字  value是渲染的东西  如果是应用
leftWords 是左边有什么文字  rightWords 是右边有什么文字
条件渲染的就加个数组child child中的id是要等于的值  val是内容 如果是链接下载的加个链接的地址 link 指定
**/
export default {
  components: { Table },
  props: {
    // 详情哪些项
    detailsItemArr: {
      default: () => [],
      type: Array
    },
    // 一行是几列
    column: {
      default: 3,
      type: Number
    },
    detailsTitle: {
      default: '详情',
      type: String
    },
    // 详情的
    detailObj: {
      default: () => { return {} },
      type: Object
    },
    // 表格标题
    // eslint-disable-next-line vue/require-default-prop
    tableTitle: {
      type: String
    },
    // table表头数据
    itemData: {
      default: () => [],
      type: Array
    },
    listData: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      dialogImageVisible: false,
      // 预览图片
      previewImg: ''
    }
  },
  watch: {
    // 加这个参数 whetherShown  判断这个值是根据有值才显示
    detailObj: {
      handler(newVal) {
        if (Object.values(newVal).length === 0) return
        this.detailsItemArr.forEach((item, index) => {
          if (item.whetherShown && !newVal[item.value]) {
            this.detailsItemArr.splice(index, 1)
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 下载的
    downloadFile,
    // 直接查看 不下载了
    viewFile(url) {
      window.open(this.$fileUrl + url)
    },
    fileLinkToStreamDownload(url, fileName) {
      var ajax = new XMLHttpRequest()
      ajax.open('GET', url, true)
      ajax.setRequestHeader('Cache-Control', 'no-cache')
      ajax.setRequestHeader('Access-Control-Allow-Origin', '*')
      ajax.responseType = 'blob'
      ajax.onload = e => {
        const res = e.target.response
        const blob = new Blob([res], { type: 'application/pdf;charset=UTF-8' }) // 这里的res为后端返回的流数据
        const aLink = document.createElement('a')
        aLink.download = fileName// 下载文件的名字
        aLink.href = URL.createObjectURL(blob)
        aLink.click()
      }
      ajax.send()
    },
    // 预览图片
    previewPicture(url) {
      this.dialogImageVisible = true
      this.previewImg = this.$fileUrl + url
    }
  }
}

</script>

<style scoped lang="scss">
.detailsPageBox .el-descriptions {
  padding: 20px 20px 0;
}
.detailsPageBox /deep/ .path{
  color: $mainBlue;
  text-decoration: underline;
}
.detailsPageBox .el-descriptions-row td {
  padding-bottom: 28px !important;
}

.detailsPageBox .tableBox {
  margin: auto;
  text-align: center;
  width: calc(100% - 128px);
}

.detailsPageBox .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}
.detailsPageBox /deep/.el-descriptions--medium:not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 28px;
}

.detailsPageBox .el-descriptions .el-descriptions__body .imageBox {
  width: 178px;
  height: 108px;
  border-radius: 4px;
  cursor: pointer;
}
.detailsPageBox .el-descriptions .el-descriptions__body i {
  font-style: normal;
}
.detailsPageBox .el-descriptions /deep/ .el-descriptions__body {
  padding: 0 32px;
  color: $mainFontColor;
}
.detailsPageBox /deep/ .el-descriptions-item__label:not(.is-bordered-label) {
  color: $assistColor7;
}
/deep/ .el-descriptions-item__content {
  color: $mainFontColor;
}
/deep/ .el-dialog {
  min-width: 1054px;
  min-height: 605px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .el-dialog__headerbtn {
    width: 30px;
    height: 30px;
    background: rgba(51, 51, 51, 0.79);
    border-radius: 0px 4px 0px 4px;
    top: 0;
    right: 0;
    font-size: 20px;
    line-height: 30px;
  }
  .el-dialog__close {
    color: #fff;
  }
  img {
    max-width: 100%;
  }
}
</style>
